import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GatekeeperService } from "../gate-keeper/gatekeeper.service";
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { get } from 'lodash';

@Injectable({
    providedIn: 'root'
})
export class AdobeExperienceManagerService {
    resultGraphQlAEM: Object;
    information: string;

    constructor(private readonly http: HttpClient, private readonly gatekeeperService: GatekeeperService) { }

    async setUpAEM(): Promise<void> {
        try {
            this.resultGraphQlAEM = await firstValueFrom(this.http.get<Object>(environment.AEM));
        } catch (error) {
            throw new Error(`Error while fetching data from AEM: ${error}`);
        }
    }

    getAEMData(path: string): string {
        return get(this.resultGraphQlAEM, path);
    }

    translate(value: string, ...args: any[]): string | null {
        this.gatekeeperService.getEnableAEMGatekeeper().subscribe((isEnableAEMGatekeeper) => {
            this.information = (isEnableAEMGatekeeper && this.isAemPath(value)) ? this.getAEMData(value) : value;

            if (args) {
                args.forEach(arg => {
                    this.information = this.information.replace(/\${(.*?)}/g, (_, key) => {
                        return arg[key] ?? '';
                    });
                });
            }
        });


        return this.decodeHtmlEntities(this.information);
    }

    isAemPath(value: string): boolean {
        return value.includes('data.');
    }

    decodeHtmlEntities(text: string): string {
        const entities: { [key: string]: string } = {
            '&lt;': '<',
            '&gt;': '>',
            '&amp;': '&',
            '&nbsp;': '\u00A0'
        };

        return text?.replace(/&lt;|&gt;|&amp;|&nbsp;/g, (match) => entities[match]);
    }
}