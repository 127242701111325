<div id="appHeaderWrapper" class="app-header-wrapper" #content="pwContent" pwContent>
  <div class="secondary-cta-wrapper linkWrapper" *ngIf="!isUserInMainPage">
    <div *ngIf="router.url.includes('rebalancing-your-portfolio')">
      <a c11n-link tabindex="0" (click)="navigateToPortfolioHealthCheckPage()" (keyup.enter)="navigateToPortfolioHealthCheckPage()" [iconName]="'link-left'" [iconPosition]="'leading'">
        Go back to overview
      </a>
    </div>
    <div class="app-nav-link c11n-text-md" *ngIf="!router.url.includes('/portfolio-health-check')" >
      <a 
        c11n-link 
        (click)="navigateBackToPortfolioWatch()" 
        (keypress)="navigateBackToPortfolioWatch()"
        [fontWeight]="'bold'" 
        [noWrap]="true" 
        [iconName]="'link-left'" 
        [iconPosition]="'leading'"
        [ariaLabel]="content['PortfolioTester_BackToPortfolioWatch'] | getFromAEM" 
        tabindex="0" 
        class="button-cta"
      >
        <span [innerHTML]="content['PortfolioTester_BackToPortfolioWatch'] | getFromAEM"></span>
      </a>
    </div>

    <div class="hypothetical-button-wrapper" *ngIf="router.url.startsWith('/hypothetical')">
      <c11n-button 
        class="hypothetical-button"
        [labelText]="content['TesterTool_HypotheticalOverview_CloseScenario'] | getFromAEM"
        (clickEvent)="navigateBackToPortfolioWatch()" 
        (keypress)="navigateBackToPortfolioWatch()"
      ></c11n-button>
      <c11n-button 
        class="hypothetical-button"
        [labelText]="content['TesterTool_HypotheticalOverview_EditScenario'] | getFromAEM"
        (clickEvent)="navigateToTesterPage()"
      ></c11n-button>
    </div>
  </div>

  <div id="primaryCTAWrapperExp" class="primary-cta-wrapper-exp" *ngIf="($isFailure | async) === false; else pwFailureBannerTitle">
    <div class="page-title" *ngIf="!router.url.includes('tester-tool') && ($isLoading | async) !== true">
      <div *ngIf="!isEnableAEMGatekeeper">
        <h1 
        *ngIf="!router.url.includes('/hypothetical') && !router.url.includes('/portfolio-health-check')" 
        class="acessibility" 
        tabindex="0" 
        aria-live="polite"
        [attr.aria-label]="content[pageTitle] + ' for ' + getDisplayName()" 
        >
          {{ content[pageTitle] | getFromAEM }} {{' for ' + getDisplayName() }}
        </h1>
        <h1 
          *ngIf="router.url.includes('/hypothetical')" 
          class="acessibility" 
          tabindex="0" 
          aria-live="polite"
          [attr.aria-label]="content[pageTitle] + ' for ' + getDisplayName()" 
        >
          {{ content['PortfolioTester_HypotheticalAnalysis'] | getFromAEM }} {{' for ' + getDisplayName() }}
        </h1>
      </div>
      <div *ngIf="isEnableAEMGatekeeper">
        <h1 
        *ngIf="!router.url.includes('/hypothetical') && !router.url.includes('/portfolio-health-check')" 
        class="acessibility" 
        tabindex="0" 
        aria-live="polite"
        [attr.aria-label]="content[pageTitle] + ' for ' + getDisplayName()" 
        >
          {{ content[pageTitle] | getFromAEM: { 'selected-group-name': getDisplayName() } }}
        </h1>
        <h1 
          *ngIf="router.url.includes('/hypothetical')" 
          class="acessibility" 
          tabindex="0" 
          aria-live="polite"
          [attr.aria-label]="content[pageTitle] + ' for ' + getDisplayName()" 
        >
          {{ content['PortfolioTester_HypotheticalAnalysis'] | getFromAEM: { 'selected-group-name': getDisplayName() } }}
        </h1>
      </div>
    </div>
    <div class="tools-section-exp-wrapper"
      *ngIf="!(router.url.includes('/tester-tool') || router.url.startsWith('/hypothetical'))">
      <ng-template [ngTemplateOutlet]="toolsSection"></ng-template>
    </div>
  </div>

  <ng-template #pwFailureBannerTitle>
    <div class="page-title" *ngIf="!router.url.includes('tester-tool') && ($isLoading | async) !== true">
      <span>{{ content[pageTitle] | getFromAEM: { 'selected-group-name': getDisplayName() } }} </span>
    </div>
  </ng-template>

  <div class="row">
    <div class="overview-content">
      <div *ngIf="!router.url.includes('/tester-tool')" class="overview">
        <div class="disclaimer" *ngIf="customizedAccountsView">
          <p class="c11n-text-md c11n-link--reinforced">
            {{ content['PWHeader_CustomizedAccountsDisclaimer_1'] | getFromAEM }}
            <a 
              c11n-link 
              [href]="modernizedCustomizeView" 
              [variant]="'secondary-reinforced'" 
              [innerHtml]="content['PWHeader_CustomizedAccountsDisclaimer_CustomLink'] | getFromAEM">
            </a>
            {{ content['PWHeader_CustomizedAccountsDisclaimer_2'] | getFromAEM }}
          </p>
        </div>
      </div>
    </div>
  </div>

  <ng-template #toolsSection>
    <div id="headerToolsSection" class="tools-section" *ngIf="!router.url.startsWith('/hypothetical')">
      <a c11n-link id="pageHelpLink" tabindex="0" (click)="openInlineDrawerPageHelp()"
        [variant]="'secondary-independent'" [fontWeight]="'normal'" (keyup.enter)="openInlineDrawerPageHelp()"
        [attr.aria-label]="content['PWOverview_Help'] | getFromAEM" [iconName]="'help'" class="button-cta">{{
        content['PWOverview_Help'] | getFromAEM }}
      </a>
      <a c11n-link id="printLink" target="_blank" tabindex="0" (click)="print(); printLinkClicked.emit(defaultData)"
        [variant]="'secondary-independent'" [fontWeight]="'normal'"
        (keyup.enter)="print(); printLinkClicked.emit(defaultData)" class="button-cta">{{ content['PWOverview_Print'] |
        getFromAEM }}
      </a>
      <a *ngIf="!router.url.includes('/portfolio-health-check')" c11n-link tabindex="0" (click)="navigateToTesterPage()" [variant]="'secondary-independent'"
        [fontWeight]="'normal'" (keyup.enter)="navigateToTesterPage()" class="button-cta">{{
        content['PWOverview_PortfolioTester'] | getFromAEM }}
      </a>
    </div>
  </ng-template>
</div>