import { AfterViewInit, Component, Input, OnChanges, OnDestroy } from '@angular/core';
import * as d3 from 'd3';
import {
  ModernizedStackedBarChartData,
  ModernizedStackedBarChartSlice,
} from '../../models/chart-models/modernized-stacked-bar-chart';

@Component({
  selector: 'stacked-bar-chart',
  templateUrl: './stacked-bar-chart.component.html',
  styleUrls: ['./stacked-bar-chart.component.scss'],
})
export class StackedBarChartComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input() barChartData: ModernizedStackedBarChartData;
  @Input() isFullWidth: boolean;

  xFunction;
  colorFunction;
  height: number = 80;
  width: number = 617;
  hashedChartId: string;

  ngAfterViewInit() {
    if (this.barChartData) {
      this.createBarChart();
    }

    console.log(this.barChartData)

  }

  ngOnChanges() {
    if (this.barChartData) {
      this.createBarChart();
    }
  }

  createBarChart() {
    this.hashedChartId = '#' + this.barChartData.chartId;
    d3.select(this.hashedChartId).html('');
    this.height = this.barChartData.chartBarHeight ? this.barChartData.chartBarHeight : this.height;
    const svg = d3.select(this.hashedChartId).append('svg');

    this.setupColorFunction();
    this.setupXFunction();
    this.addSvgBaseAttributes(svg);

    if (this.barChartData.usesMasks) {
      this.addMasks(svg);
    }

    this.addBarSlices(svg);
  }

  setupColorFunction() {
    this.colorFunction = d3
      .scaleOrdinal()
      .domain(this.barChartData.chartSlices.map((slice) => slice.name))
      .range(this.barChartData.chartSlices.map((slice) => slice.color));
  }

  setupXFunction() {
    this.xFunction = d3.scaleLinear().domain([0, 1]).range([0, this.width]);
  }

  addSvgBaseAttributes(svg) {
    svg
      .attr('preserveAspectRatio', 'xMidYMid meet')
      .attr('viewBox', '0 0 ' + this.width + ' ' + this.height)
      .style('overflow', 'visible');
  }

  addBarSlices(svg) {
    const data = this.barChartData.chartSlices;
    const formattedData = this.formatData(data);

    const formatPercent = this.xFunction.tickFormat(null, '%');

    const g = svg
      .append('g')
      .attr('class', 'barGroup')
      .attr('stroke', 'white')
      .attr('stroke-width', '3px')
      .selectAll('rect')
      .data(formattedData);

    const rect = g
      .join('rect')
      .attr('fill', (d) => this.colorFunction(d.name))
      .attr('x', (d) => this.xFunction(d.startValue))
      .attr('y', 0)
      .attr('width', 0)
      .attr('height', this.height);

    rect
      .transition()
      .duration(1000)
      .attr('width', (d) => this.xFunction(d.endValue) - this.xFunction(d.startValue))
      .delay(0);

    rect.append('title').text((d) => `${d.label}: ${formatPercent(d.value)}`);
  }

  formatData(data: ModernizedStackedBarChartSlice[]) {
    const total = d3.sum(data, (d) => d.value);
    let value = 0;
    return data.map((d) => ({
      name: d.name,
      value: d.value / total,
      label: d.label,
      startValue: value / total,
      endValue: (value += d.value) / total,
    }));
  }

  addMasks(svg) {
    svg.attr('class', 'showPatternMask');

    const defs = svg.append('defs');

    const patternWithLines = defs
      .append('pattern')
      .attr('id', 'stripey-lines')
      .attr('width', '10')
      .attr('height', '10')
      .attr('patternUnits', 'userSpaceOnUse')
      .attr('patternTransform', 'rotate(45 0 0)');
    patternWithLines
      .append('line')
      .attr('x1', '0')
      .attr('y1', '0')
      .attr('x2', '0')
      .attr('y2', '10')
      .attr('style', 'stroke:white');

    const maskForPatternedBars = defs.append('mask').attr('id', 'mask-for-patterned-bars');
    maskForPatternedBars
      .append('rect')
      .attr('x', '0')
      .attr('y', '0')
      .attr('width', '100%')
      .attr('height', '100%')
      .attr('fill', 'url(#stripey-lines)');
  }

  ngOnDestroy() {
    d3.select(this.hashedChartId).remove();
  }
}
