import { Observable, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

import localParsedData from '../../../assets/content.json'
import { GatekeeperService } from '../gate-keeper/gatekeeper.service';

@Injectable({
  providedIn: 'root',
})
export class ContentAsAService {
  contentPayload = new BehaviorSubject<any>('');

  constructor(
    private readonly gatekeeperService: GatekeeperService
  ) {
    this.loadContentPayload();
  }

  getContentPayload(): Observable<any> {
    return this.contentPayload.asObservable();
  }

  loadContentPayload() {
    this.gatekeeperService.getEnableAEMGatekeeper().subscribe((isEnableAEMGatekeeper) => {
      if (isEnableAEMGatekeeper) {
        this.loadContentFromAEMJson();
      } else {
        this.loadContentFromAEMRetailHeadless()
      }
    })
  }

  private loadContentFromAEMJson() {
    const response = require('../../../assets/aem-content.json')
    this.contentPayload.next(response.content.Data.Pw1Chunk.content);
  }

  private loadContentFromAEMRetailHeadless() {
    this.contentPayload.next(localParsedData.content.Data.Pw1Chunk.content);
  }
}
