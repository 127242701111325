import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ChartColors } from '../../models/chart-models/chart-colors';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ModernizedAssetMixColors,
  ModernizedStackedBarChartData,
} from '../../models/chart-models/modernized-stacked-bar-chart';
import { PortfolioCardsService } from '../../services/portfolio-cards/portfolio-cards.service';
import { PortfolioDataService } from '../../services/portfolio-data-service/portfolio-data.service';
import { LoggerService } from '../../services/logger/logger.service';
import { LoggerCode } from '../../../models/logger';
import { combineLatest, firstValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AccountInfo } from 'src/app/models/chart-data-response-models/account-group-details';
import { AdobeAnalyticsService } from 'src/app/services/adobe-analytics/adobe-analytics.service';
import { TargetAssetRequest, TargetAssetType } from 'src/app/models/target-asset-request';
import { GatekeeperService } from 'src/app/services/gate-keeper/gatekeeper.service';
@Component({
  selector: 'PW1-suggested-target-asset-mix',
  templateUrl: './suggested-target-asset-mix.component.html',
  styleUrls: ['./suggested-target-asset-mix.component.scss'],
})
export class SuggestedTargetAssetMixComponent implements OnInit {
  chartData;
  @Input() chartId: string;
  @Input() isFullWidth: boolean;
  bondsAndStocks: number[];
  suggestedTargetMixChartData: ModernizedStackedBarChartData;
  currentMixChartData: ModernizedStackedBarChartData;
  chartColorMap = new Map();
  portfolioModel: string;
  bestYear: string;
  worstYear: string;
  worstYearAverage: string;
  bestYearAverage: string;
  averageReturn: string;
  fromYear: string;
  toYear: string;
  yearsWithLoss: string;
  totalYears: string;
  percentYearsOfLoss: string;
  hasLoadedTargetMixRiskReturnData: boolean = false;
  viewId: string;
  saveTargetMixError: boolean = false;
  selectedAccountGroup: AccountInfo;
  isEnabledAEMGatekeeper: boolean;
  historicalAnalysisYearRange: string;
  yearsWithLossText: string;

  // eslint-disable-next-line max-params
  constructor(
    private cdref: ChangeDetectorRef,
    public router: Router,
    private loggerService: LoggerService,
    private portfolioDataService: PortfolioDataService,
    private cardsService: PortfolioCardsService,
    private route: ActivatedRoute,
    private adobeAnalyticsService: AdobeAnalyticsService,
    private gatekeeperService: GatekeeperService,
  ) {
    this.chartColorMap = ChartColors.getColorMap();
    this.bondsAndStocks = this.portfolioDataService.newMix;
  }
  chartDataPayload$: Observable<any>;
  TARGET_ASSET_MIX_STATUS = {
    NEW: 'A',
    UPDATE: 'U',
  };
  isNewMix: boolean;
  viewName: string;

  ngOnInit() {
    this.route.data.subscribe((data) => {
      this.adobeAnalyticsService.trackRoutingData(data);
    });

    this.chartDataPayload$ = this.portfolioDataService.getChartDataPayload();
    this.suggestedTargetMixChartData = {
      chartId: 'targetAssetMixId',
      chartLabel: undefined,
      chartSlices: [
        {
          name: 'stocks',
          label: 'Stocks',
          value: this.bondsAndStocks[1],
          color: ModernizedAssetMixColors.getColorMap().get('stocks'),
        },
        {
          name: 'bonds',
          label: 'Bonds',
          value: this.bondsAndStocks[0],
          color: ModernizedAssetMixColors.getColorMap().get('bonds'),
        },
      ],
      usesMasks: true,
      chartBarHeight: 64,
    };

    this.chartDataPayload$.subscribe((data) => {
      this.chartData = data;
      this.currentMixChartData = {
        chartId: 'currentAssetMixId',
        chartLabel: undefined,
        chartSlices: [
          {
            name: 'stocks',
            label: 'Stocks',
            value: data.assetTypes.chart.stocks,
            color: ModernizedAssetMixColors.getColorMap().get('stocks'),
          },
          {
            name: 'bonds',
            label: 'Bonds',
            value: data.assetTypes.chart.bonds,
            color: ModernizedAssetMixColors.getColorMap().get('bonds'),
          },
          {
            name: 'shortTermReserves',
            label: 'Short-term reserves',
            value: data.assetTypes.chart.shortTermReserves,
            color: ModernizedAssetMixColors.getColorMap().get('shortTermReserves'),
          },
          {
            name: 'other',
            label: 'Other',
            value: data.assetTypes.chart.others,
            color: ModernizedAssetMixColors.getColorMap().get('others'),
          },
        ],
        usesMasks: false,
        chartBarHeight: 32,
      };
    });
    this.cdref.detectChanges();
    this.getPortfolioModel();
    this.getTargetMixRiskReturn();

    combineLatest([
      this.portfolioDataService.getCurrentViewName(),
      this.portfolioDataService.getSelectedAccountGroupPayload(),
      this.portfolioDataService.getAccountGroupsDetailsListPayload(),
    ])
      .pipe(
        map(([viewName, accountGroup, accountGroupsDetails]) => ({
          viewName: viewName,
          accountGroup: accountGroup,
          accountGroupsDetails: accountGroupsDetails,
        })),
      )
      .subscribe((data) => {
        this.viewId = data.accountGroup.ViewID ?? "";
        this.viewName = data.viewName;
        this.selectedAccountGroup = data.accountGroup;
        this.setPostObjectPorperties();
      });

    this.gatekeeperService.getEnableAEMGatekeeper().subscribe((isEnabledAEMGatekeeper) => {
      this.isEnabledAEMGatekeeper = isEnabledAEMGatekeeper;
    });
  }

  setPostObjectPorperties() {
    if (this.viewId) {
      this.chartDataPayload$.subscribe((data) => {
        if (
          Object.values(data.assetTypeTarget).findIndex((targetValue) => targetValue != 0) == -1
        ) {
          this.isNewMix = true;
        } else {
          this.isNewMix = false;
        }
      });
    } else {
      this.isNewMix = true;
      this.viewId = '';
    }
  }

  // eslint-disable-next-line complexity
  getPortfolioModel() {
    if (this.bondsAndStocks[0] >= 70) {
      this.portfolioModel = 'Income';
    } else if (this.bondsAndStocks[0] < 70 && this.bondsAndStocks[0] > 30) {
      this.portfolioModel = 'Balanced';
    } else {
      this.portfolioModel = 'Growth';
    }
  }
  async saveTargetAllocationBtn($event) {
    $event.currentTarget.disabled = true;
    await firstValueFrom(this.portfolioDataService.getSelectedAccountGroupPayload()).then(
      (data) => {
        this.viewId = data.ViewID;
      },
    );
    let updatedTargetAssetMix = new TargetAssetRequest(this.viewId ?? "");
    if (this.isNewMix) {
      updatedTargetAssetMix = new TargetAssetRequest(this.viewId ?? "", this.viewName);
    }
    updatedTargetAssetMix.addAssetAllocation(TargetAssetType.Stocks, this.suggestedTargetMixChartData.chartSlices[0].value);
    updatedTargetAssetMix.addAssetAllocation(TargetAssetType.Bonds, this.suggestedTargetMixChartData.chartSlices[1].value);
    updatedTargetAssetMix.addAssetAllocation(TargetAssetType.ShortTermReserves, 0.0);

    this.adobeAnalyticsService.clickedOnSaveTargetFromSuggested();
    this.loggerService.info(
      {
        message: 'Saving Target Mix from Questionnaire tool',
      },
      LoggerCode.SAVING_TARGET_MIX_FROM_QUESTIONNAIRE_TOOL,
    );

    this.portfolioDataService.setTargetAllocation(updatedTargetAssetMix, this.isNewMix).subscribe(
      () => {
        this.portfolioDataService.setTargetMixSuccess(true);
        this.saveTargetMixError = false;
        if (!this.viewId) {
          this.portfolioDataService.setSelectedAccountGroupDetails(this.selectedAccountGroup);
          this.portfolioDataService.loadChartDataPayload();
        } else {
          this.chartData.assetTypeTarget = {
            bonds: updatedTargetAssetMix.getAssetAllocation(TargetAssetType.Bonds).percentageAssetAllocation,
            shortTermReserves: updatedTargetAssetMix.getAssetAllocation(TargetAssetType.ShortTermReserves).percentageAssetAllocation,
            stocks: updatedTargetAssetMix.getAssetAllocation(TargetAssetType.Stocks).percentageAssetAllocation,
          };
          this.chartData.riskReturnAnalysisResponse.targetAllocationRiskAnalysis = {
            averageReturn: this.averageReturn,
            bestYear: this.bestYear,
            bestYearAverage: this.bestYearAverage,
            fromYear: this.fromYear,
            percentYearsOfLoss: this.percentYearsOfLoss,
            toYear: this.toYear,
            totalYears: this.totalYears,
            worstYear: this.worstYear,
            worstYearAverage: this.worstYearAverage,
            yearsWithLoss: this.yearsWithLoss,
          };
          this.portfolioDataService.updateChartData(this.chartData);
        }
        this.cardsService.navigateToPage('/asset-mix');
        this.loggerService.info(
          {
            message: 'Saved new Target Mix',
          },
          LoggerCode.TARGET_MIX_SAVED,
        );
      },
      (error) => {
        this.loggerService.info(
          {
            message: error.message,
            status: error.status,
          },
          LoggerCode.TARGET_MIX_NOT_SAVED,
        );
        this.saveTargetMixError = true;
      },
    );
  }

  chooseTarget() {
    this.adobeAnalyticsService.clickedOnChooseTargetMix();
    this.router.navigate(['target-mix-custom-view']);
  }

  getTargetMixRiskReturn() {
    this.portfolioDataService.loadTargetMixRiskReturnPayload(
      this.bondsAndStocks[1].toString(),
      this.bondsAndStocks[0].toString(),
    );
    this.portfolioDataService.getTargetMixRiskReturnPayload().subscribe((data: any) => {
      if (data) {
        this.hasLoadedTargetMixRiskReturnData = true;
        this.bestYear = data.riskAnalysisType1.bestYear;
        this.worstYear = data.riskAnalysisType1.worstYear;
        this.worstYearAverage = data.riskAnalysisType1.worstYearAverage;
        this.bestYearAverage = data.riskAnalysisType1.bestYearAverage;
        this.averageReturn = data.riskAnalysisType1.averageReturn;
        this.fromYear = data.riskAnalysisType1.fromYear;
        this.toYear = data.riskAnalysisType1.toYear;
        this.yearsWithLoss = data.riskAnalysisType1.yearsWithLoss;
        this.totalYears = data.riskAnalysisType1.totalYears;
        this.percentYearsOfLoss = data.riskAnalysisType1.percentYearsOfLoss;

        this.historicalAnalysisYearRange = `${this.fromYear}-${this.toYear}`;
        this.yearsWithLossText = `${this.yearsWithLoss} of ${this.totalYears} (${this.percentYearsOfLoss}%)`;
      } else {
        this.hasLoadedTargetMixRiskReturnData = false;
      }
    });
  }

  goBackToOverview() {
    this.adobeAnalyticsService.clickedBackToOverview();
    this.router.navigate(['']);
  }

  retakeQuestionnaire() {
    this.adobeAnalyticsService.clickedOnRetakeQuestionnaire();
    this.router.navigate(['target-mix-questionnaire']);
  }
}
